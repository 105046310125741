import React, { Component } from "react";
import { Grid, MenuItem, Checkbox, Divider } from "@material-ui/core";
import Container from "../../components/Container";
import {
  fetchWebApi,
  putDashboardMultipartWebApi,
  putDashboardWebApi,
} from "../../../webApis/apiResource";
import CustomInput from "../../components/Input";
import { getAccessToken } from "../../../services/browserStorage";
import FormValidator from "../../../services/FormValidator";
import CrossWhiteIcon from "../../../assets/icons/cross_icon_white.svg";
import SaveIcon from "../../../assets/icons/save.svg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { requestCountriesList } from "../../../modules/actions/countries.action";
import {
  requestUserList,
  requestIntegrationAdminsList,
  requestSupportAdminsList,
} from "../../../modules/actions/userList.action";
import { connect } from "react-redux";
import LicenseModal from "./LicenceModal";
import {
  convertImageToBase64,
  DUMMY_FUNCTION,
  enableFormDirtiness,
  getComponentTexts,
  USER_ROLE_IDS,
  webApiCheck,
} from "../../../services/commonMethods";
import UploadButton from "../../components/UploadFile/UploadFile";
import DevicePreviewPicker from "../../components/DevicePreviewPicker/DevicePreviewPicker";
import DevicePreview from "../../components/DevicePreview/DevicePreview";
import {
  successToast,
  errorToast,
} from "../../../modules/actions/toast.action";
import { requestServicesList } from "../../../modules/actions/services.action";
import { requestCompaniesList } from "../../../modules/actions/companyList.action";
import { requestPaymentGatewaysList } from "../../../modules/actions/paymentGatewaysList.action";
import { requestVendingMachineBoardsList } from "../../../modules/actions/vendingMachineBoard.action";
import {
  DEFAULT_MACHINE_BOARD_QUANITITY,
  ENUMS,
  VENDIFY_BOARD_NAMES,
} from "../../../constants";
import {
  requestCitiesList,
  resetCitiesList,
} from "../../../modules/actions/citiesList.action";
import {
  requestStatesList,
  resetStatesList,
} from "../../../modules/actions/statesList.action";
import CustomAutoComplete from "../../components/AutoComplete/AutoComplete";
import {
  checkShowModal,
  setFormDirtiness,
  resetFormDirtiness,
} from "../../../modules/actions/cancelConfirm.action";

import "react-tabs/style/react-tabs.css";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { manualBreadcrumbMapping } from "../../../constants/breadcrumbMapping";
import { APP_ROUTES } from "../../../constants/appRoutes";
import EditModal from "../../components/Modals/EditModal";
import CustomImagePicker from "../../components/CustomImagePicker/CustomImagePicker";
import DefaultCompanyLogo from "../../../assets/icons/vendify_logo.svg";

//Component to update company details.
class EditCompany extends Component {
  state = {
    companyAdminContactNumber: "",
    companyAdminEmail: "",
    companyAdminName: "",
    companyId: null,
    name: "",
    type: ENUMS.COMPANY_TYPE.WITH_FRANCHISE,
    url: "",
    operationalCountry: "",
    validFrom: "",
    validTo: "",
    paymentGateways: null,
    primaryIntegrationAdminId: null,
    companyAdminId: null,
    vendingMachineBoard: null,
    integrationAdminIds: [],
    licenseVO: null,
    serviceIds: [],
    supportAdmin: null,
    supportAdminId: null,
    accountDetail: {
      accountName: null,
      accountNumber: null,
      accountType: null,
      bankName: null,
      gstNumber: null,
      ifscCode: null,
    },
    address: {
      lineOne: "",
      lineTwo: "",
      city: "",
      cityId: null,
      state: "",
      stateId: null,
      stateCode: "",
      countryCode: "",
      countryName: "",
      countryId: null,
      zipCode: "",
    },
    version: 0,
    boards: [],
    nonOnboardingFieldsRequired: false,
    showLicenceModal: false,
    numberOfBoards_ESP32: null,
    numberOfBoards_PINE64: null,
    disableCompanyTypeCheckbox: false,
    dbOperationalCountryId: null,
    showOperationalCountryModal: false,
    logo: "",
    primaryColor: "",
    secondaryColor: "",

    // fileUpload: null,
    icon: "",
  };

  rules = {
    name: { required: true },
    operationalCountry: { required: true },
    url: { url: true },
    validFrom: { required: true },
    validTo: { required: true },
    companyAdminContactNumber: {
      required: true,
      isMobile: true,
      exactlength: 10,
    },
    companyAdminName: { required: true },
    companyAdminEmail: { required: true, email: true },
    integrationAdminIds: { required: true },
  };

  setNonOnboardingFieldsRequired = () => {
    this.rules = {
      ...this.rules,
      address: {
        countryCode: { required: true },
        lineOne: { required: true },
        city: { required: true },
        countryId: { required: true },
        cityId: { required: true },
        stateId: { required: true },
        state: { required: true },
        zipCode: { required: true },
      },
      numberOfBoards_PINE64: {
        required: true,
        number: true,
        minVal: DEFAULT_MACHINE_BOARD_QUANITITY,
      },
      numberOfBoards_ESP32: {
        required: true,
        number: true,
        minVal: DEFAULT_MACHINE_BOARD_QUANITITY,
      },
      paymentGateways: { required: true },
    };
  };

  submit = () => {
    let data = {
      ...this.state,
      address: { ...this.state.address },
      accountDetail: { ...this.state.accountDetail },
    };

    let { numberOfBoards_ESP32, numberOfBoards_PINE64 } = data;

    if (data.serviceIds.length === 0) {
      data.serviceIds = null;
    } else {
      data.serviceIds = data.serviceIds.map((ele) => ele.id);
    }
    //change values of board.
    if (numberOfBoards_ESP32) {
      data.boards.push({
        boardType: "ESP32",
        numberOfBoards: numberOfBoards_ESP32,
      });
    }
    if (numberOfBoards_PINE64) {
      data.boards.push({
        boardType: "PINE64",
        numberOfBoards: numberOfBoards_PINE64,
      });
    }

    data.integrationAdminIds = data.integrationAdminIds.map((ele) => ele.id);
    data.paymentGateways = [
      this.props.paymentGatewaysList.filter(
        (ele) => ele.id === +data.paymentGateways
      )[0],
    ];
    data.operationalCountry = data.operationalCountry.name;

    if (data.address.countryId !== null) {
      data.address.countryId = data.address.countryId.id;
    }
    if (data.address.cityId !== null) {
      data.address.cityId = data.address.cityId.id;
    }
    if (data.address.stateId !== null) {
      data.address.stateId = data.address.stateId.id;
    }
    let urlEndPoint = `/api/company/${data.companyId}`;
    if (data.primaryIntegrationAdminId === null) {
      data.primaryIntegrationAdminId = data.integrationAdminIds[0].id;
    }
    let {
      companyAdminContactNumber,
      companyAdminEmail,
      companyAdminName,
      operationalCountry,
      operationalCountryCode,
      operationalCountryId,
      integrationAdminIds,
      name,
      primaryIntegrationAdminId,
      url,
      validFrom,
      validTo,
      supportAdminId,
    } = data;
    data.onBoardingCO = {
      companyAdminContactNumber,
      companyAdminEmail,
      companyAdminName,
      operationalCountry,
      operationalCountryCode,
      operationalCountryId,
      integrationAdminIds,
      name,
      primaryIntegrationAdminId,
      url,
      validFrom,
      validTo,
      supportAdminId,
    };
    let { onBoardingCO } = data;
    let nonOnBoardingCO = { ...data };
    delete nonOnBoardingCO["onBoardingCO"];


    putDashboardWebApi(
      getAccessToken(),
      urlEndPoint,
      this.state.nonOnboardingFieldsRequired
        ? { nonOnBoardingCO, onBoardingCO }
        : { onBoardingCO }
    ).then((Response) => {
      if (webApiCheck(Response)) {
        this.props.successToast({
          message: `${data.name} updated successfully!`,
        });

        //If company had no license, then show the license agreement.
        if (data.licenseVO === null && Response.data.type) {
          this.setState({
            ...Response.data,
            type: Response.data.type.name,
            showLicenceModal: true,
          });
        } else {
          this.props.history.push(`/companies`);
        }
      }
    });
  };

  submitMiddleware = (data = this.state) => {
    if (data.dbOperationalCountryId !== data.operationalCountry.id) {
      return this.setState({ showOperationalCountryModal: true });
    }
    this.submit();
  };

  btns = [
    {
      type: "submit",
      btnProps: {
        text: [<img src={SaveIcon} alt="save" key={0} />, "Update"],
        className: "save-btn float-right",
        variant: "contained",
        method: this.submitMiddleware,
      },
    },
    {
      type: "button",
      btnProps: {
        text: [<img src={CrossWhiteIcon} alt="cross" key={0} />, "Cancel"],
        className: "save-btn cancel-btn float-right",
        variant: "contained",
        method: () => {
          this.props.history.goBack();
        },
      },
    },
  ];

  //checks if user has permission to edit onboard fields.
  checkUserHasOnBoardPermission = () => {
    if (this.props.me.role.id === USER_ROLE_IDS["integration-admin"]) {
      return false;
    }
    if (this.props.me.role.id === USER_ROLE_IDS["company-admin"]) {
      return false;
    }

    return true;
  };

  onboardFieldsName = [
    "companyAdminContactNumber",
    "companyAdminEmail",
    "companyAdminName",
    "operationalCountry",
    "integrationAdminIds",
    "supportAdminId",
    "name",
    "primaryIntegrationAdminId",
    "url",
    "validFrom",
    "validTo",
  ];
  componentWillUnmount() {
    this.props.resetFormDirtiness();
  }

  componentDidMount() {
    //Fetch current company details from the server.
    fetchWebApi(
      getAccessToken(),
      `/api/company/${this.props.match.params.id}`
    ).then((response) => {
      let {
        integrationAdmins,
        validFrom,
        validTo,
        address,
        accountDetail,
        boards,
        licenseVO,
        paymentGateways,
        supportAdmin,
        operationalCountry,
        operationalCountryCode,
        operationalCountryId,
        type,
        companyId,
        primaryColor,
        secondaryColor,
        logo,
      } = response.data;

      //Set default color coding if not set
      if (!primaryColor || !secondaryColor) {
        primaryColor = ENUMS.DEVICE_APP_COLOR_CODES.PRIMARY;
        secondaryColor = ENUMS.DEVICE_APP_COLOR_CODES.SECONDARY;
      }
      //Set default logo if no logo has been set
      if (!logo) {
        convertImageToBase64(DefaultCompanyLogo, (base64) => {
          this.setState({ logo: base64 });
        });
      }
      //get Countries list.
      this.props.requestCountriesList("/api/country", companyId);

      //get payment gateways.
      this.props
        .requestPaymentGatewaysList("/api/payment", companyId)
        .then(() => {
          //Allow only razorpay as of now.
          this.setState({
            paymentGateways: this.props.paymentGatewaysList.find(
              (gateway) => gateway.name === "Razorpay"
            ).id,
          });
        });

      //Get states and cities list according to selected country
      if (address) {
        this.props.requestStatesList(
          `/api/country/state/${address.countryId}`,
          companyId
        );
        this.props.requestCitiesList(
          `/api/country/state/city/${address.stateId}`,
          companyId
        );
      }

      //Filtering data as per api need
      let integrationAdminIds = integrationAdmins;
      let serviceIds = [];
      if (licenseVO) {
        serviceIds = licenseVO.services;
      }
      validFrom = new Date(response.data.validFrom).getInputDateFormat();
      validTo = new Date(response.data.validTo).getInputDateFormat();
      //Handling null expections.
      address = address
        ? {
            ...address,
            cityId: { name: address.city, id: address.cityId },
            stateId: {
              name: address.state,
              iso2: address.stateCode,
              id: address.stateId,
            },
            countryId: {
              name: address.countryName,
              id: address.countryId,
              iso2: address.countryCode,
            },
          }
        : this.state.address;
      accountDetail = accountDetail
        ? { ...accountDetail, accountType: accountDetail.accountType?.name }
        : this.state.accountDetail;
      let [numberOfBoards_ESP32, numberOfBoards_PINE64] = [null, null];
      boards.forEach((board) => {
        if (board.boardType.name === "ESP32") {
          numberOfBoards_ESP32 = board.numberOfBoards;
        } else if (board.boardType.name === "PINE64") {
          numberOfBoards_PINE64 = board.numberOfBoards;
        }
      });

      if (paymentGateways.length) {
        paymentGateways = paymentGateways[0]?.id;
      }
      if (response.data.address) {
        //Make non onboarding fields required if any non onboarding field is filled already.
        this.changeNumberOfBoardsRule(
          numberOfBoards_ESP32,
          numberOfBoards_PINE64
        );
        this.setNonOnboardingFieldsRequired();
        response.data.nonOnboardingFieldsRequired = true;
      }
      let supportAdminId = supportAdmin?.id;
      operationalCountry = {
        id: operationalCountryId,
        name: operationalCountry,
        iso2: operationalCountryCode,
      };
      this.setState({
        ...response.data,
        dbOperationalCountryId: operationalCountryId,
        integrationAdminIds,
        validFrom,
        validTo,
        address,
        accountDetail,
        serviceIds,
        boards: [],
        paymentGateways,
        supportAdminId,
        numberOfBoards_ESP32,
        numberOfBoards_PINE64,
        operationalCountry,
        type: type?.name || ENUMS.COMPANY_TYPE.WITH_FRANCHISE,
        disableCompanyTypeCheckbox: !!type?.name,
        primaryColor,
        secondaryColor,
        logo,
      });
    });

    //get Integration admins list.
    this.props.requestIntegrationAdminsList("/api/user/get/all?roleId=3");

    //get Support Admins list.
    this.props.requestSupportAdminsList("/api/user/get/all?roleId=4");

    //get Services List.
    this.props.requestServicesList("/api/service");

    //get vending machine boards.
    this.props.requestMachineBoardsList("/api/board");
  }

  changeNumberOfBoardsRule = (numberOfBoards_ESP32, numberOfBoards_PINE64) => {
    let { rules } = this;
    if (!numberOfBoards_ESP32 && !numberOfBoards_PINE64) {
      this.rules.numberOfBoards_ESP32 = {
        ...rules.numberOfBoards_ESP32,
        required: true,
      };
      this.rules.numberOfBoards_PINE64 = {
        ...rules.numberOfBoards_PINE64,
        required: true,
      };
    }
    if (numberOfBoards_ESP32 || numberOfBoards_PINE64) {
      this.rules.numberOfBoards_ESP32 = {
        ...rules.numberOfBoards_ESP32,
        required: false,
      };
      this.rules.numberOfBoards_PINE64 = {
        ...rules.numberOfBoards_PINE64,
        required: false,
      };
    }
  };

  handleChange = (ev) => {
    let { name } = ev.target;
    let changeInOnboardingField = this.onboardFieldsName.includes(name);
    //This prevents the case if user manually removes disabled tag from input element & tries to edit the field.
    if (!this.checkUserHasOnBoardPermission() && changeInOnboardingField) {
      return;
    }

    let payload = {
      [name]:
        ev.target.type === "number" && ev.target.value !== ""
          ? +ev.target.value
          : ev.target.value,
    };

    if (!changeInOnboardingField && !this.state.nonOnboardingFieldsRequired) {
      payload.nonOnboardingFieldsRequired = true;
      this.setNonOnboardingFieldsRequired();
    }

    if (name === "numberOfBoards_ESP32") {
      ev.preventDefault();
      this.changeNumberOfBoardsRule(
        ev.target.value,
        this.state.numberOfBoards_PINE64
      );
    } else if (name === "numberOfBoards_PINE64") {
      ev.preventDefault();
      this.changeNumberOfBoardsRule(
        this.state.numberOfBoards_ESP32,
        ev.target.value
      );
    }

    this.setState(payload, enableFormDirtiness.bind(this));
  };

  onFileChange = (event) => {
    let data = new FormData();
    data.append("file", event.target.files[0]);
    data.append("id", this.state.companyId);

    putDashboardMultipartWebApi(
      getAccessToken(),
      `/api/company/upload/cheque/${this.state.companyId}`,
      data
    );
    this.setState({ cancelCheque: event.target.files[0] });
  };


  getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Making a new FileReader
      let reader = new FileReader();

      // Converting the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        // console.log("Reader", reader);
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  
  fileChangeHandler = (event) => {
    
    // console.log("File",event.target.files[0]);

    // let { fileUpload } = this.state;

    const fileUpload = event.target.files[0];

    const fileExtension = fileUpload.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "png", "svg"];
    if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
        return false;
    }


    this.getBase64(fileUpload)
      .then(result => {
        // console.log("Result is ",result);

        this.setState({
          icon: result,
        });

        // console.log("ID",this.state.companyId);
        // console.log("State 1",this.state)
      })
      .catch(err => {
        console.log(err);
      });
    
    // this.setState({
    //   fileUpload: event.target.files[0]
    // });

  }

  handleAddressChange = (name, value) => {
    let address = { ...this.state.address, [name]: value };
    if (name === "countryId") {
      if (value) {
        let selectedCountry = this.props.countriesList.filter(
          (ele) => ele.id === value.id
        )[0];
        address.countryCode = selectedCountry.iso2;
        address.countryName = selectedCountry.name;
        this.props.requestStatesList(
          `/api/country/state/${value.id}`,
          this.state.companyId
        );
      } else {
        this.props.resetCitiesList();
        this.props.resetStatesList();
      }
      address.cityId = null;
      address.stateId = null;
    } else if (name === "stateId") {
      if (value) {
        let selectedState = this.props.statesList.filter(
          (ele) => ele.id === value.id
        )[0];
        address.state = selectedState.name;
        address.stateCode = selectedState.iso2;
        this.props.requestCitiesList(
          `/api/country/state/city/${value.id}`,
          this.state.companyId
        );
      } else {
        this.props.resetCitiesList();
      }
      address.cityId = null;
    } else if (name === "cityId" && value) {
      let selectedCity = this.props.citiesList.filter(
        (ele) => ele.id === value.id
      )[0];
      address.city = selectedCity.name;
    }

    let payload = { address };

    if (
      !this.state.changeInOnboardingField &&
      !this.state.nonOnboardingFieldsRequired
    ) {
      payload.nonOnboardingFieldsRequired = true;
      this.setNonOnboardingFieldsRequired();
    }

    this.setState(payload, enableFormDirtiness.bind(this));
  };

  handleAccountDetailsChange = (ev) => {
    this.setState(
      ({ accountDetail }) => ({
        accountDetail: { ...accountDetail, [ev.target.name]: ev.target.value },
      }),
      enableFormDirtiness.bind(this)
    );
  };
  handleIntegrationAdminChange = (ev, value) => {
    let primaryIntegrationAdminId = this.state.primaryIntegrationAdminId;
    if (value.length === 0) {
      primaryIntegrationAdminId = null;
    } else if (value.length > 1) {
      primaryIntegrationAdminId = value[0].id;
    }

    this.setState(
      { integrationAdminIds: value, primaryIntegrationAdminId },
      enableFormDirtiness.bind(this)
    );
  };

  handleOperationalCountryChange = (ev, value) => {
    if (!this.checkUserHasOnBoardPermission()) {
      return;
    }
    let payload = { operationalCountry: value };
    let selectedCountryData;
    if (value) {
      selectedCountryData = this.props.countriesList.find(
        (ele) => ele.id === value.id
      );
      payload.operationalCountryId = selectedCountryData.id;
      payload.operationalCountryCode = selectedCountryData.iso2;
    }
    return this.setState(payload, enableFormDirtiness.bind(this));
  };

  //show error toasts when parent not selected
  showWarningMessage = (parent, message) => {
    if (!parent) {
      this.props.errorToast({
        data: { message: `Please select the ${message} first` },
      });
    }
  };

  //Handle checkbox change
  handleCheckboxChange = (ev) => {
    this.setState(
      {
        [ev.target.name]: ev.target.checked
          ? ENUMS.COMPANY_TYPE.WITH_FRANCHISE
          : ENUMS.COMPANY_TYPE.WITHOUT_FRANCHISE,
      },
      enableFormDirtiness.bind(this)
    );
  };

  setDevicePreviewState = (nextState) => {
    nextState.nonOnboardingFieldsRequired = true;
    this.setNonOnboardingFieldsRequired();
    if (!this.props.formDirtBool) {
      enableFormDirtiness.bind(this);
    }
    return this.setState(nextState);
  };

  render() {
    const { citiesList, statesList } = this.props;
    const { supportAdminsList, integrationAdminsList } = this.props;
    const operationalCountryAlerts = getComponentTexts(
      "edit_company.alert_messages.operational_country"
    );

    const {
      showLicenceModal,
      disableCompanyTypeCheckbox,
      nonOnboardingFieldsRequired,
    } = this.state;
    const componentStrings = getComponentTexts("get_helper_text");
    //Breadcrumb links to be shown.
    const breadcrumbData = manualBreadcrumbMapping.apply(this, [
      APP_ROUTES.EDIT_COMPANY,
    ]);

    const resetOperationalCountryModal = () =>
      this.setState({ showOperationalCountryModal: false });

    return (
      this.state.paymentGateways && (
        <div>
          <Breadcrumb data={breadcrumbData} />
          <EditModal
            open={this.state.showOperationalCountryModal}
            alertMessage={operationalCountryAlerts.heading}
            alertHeading={operationalCountryAlerts.message}
            onClose={resetOperationalCountryModal}
            onEditForm={this.submit}
          />
          <Container title={"Update Company Details"}>
            <FormValidator
              rules={this.rules}
              data={this.state}
              btns={this.btns}
              errorToast={this.props.errorToast}
            >
              <Grid container>
                <Grid item lg={6}>
                  <Grid container>
                    <Grid item lg={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          className: "width-95",
                        }}
                        inputProps={{
                          value: this.state.name,
                          onChange: this.handleChange,
                          required: true,
                          label: "Name",
                          name: "name",
                          type: "text",
                          disabled: !this.checkUserHasOnBoardPermission(),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item lg={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          className: "width-95",
                        }}
                        inputProps={{
                          value: this.state.url,
                          onChange: this.handleChange,
                          label: "URL",
                          name: "url",
                          type: "text",
                          disabled: !this.checkUserHasOnBoardPermission(),
                          helperText: componentStrings.url_helper_text,
                        }}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <CustomAutoComplete
                        autoCompleteProps={{
                          id: "select-operational-country",
                          name: "operationalCountry",
                          options: this.props.countriesList,
                          fullWidth: true,
                          value: this.state.operationalCountry,
                          onChange: this.handleOperationalCountryChange,
                          getOptionLabel: (option) =>
                            option && option.name ? option.name : "",

                          openOnFocus: true,
                          required: true,
                          className: "width-95 overflow-hidden",
                          disabled: !this.checkUserHasOnBoardPermission(),
                        }}
                        textFieldProps={{
                          label: "Operational Country",
                          className:
                            "input-add-screen mt-3 custom-auto-padding",
                          variant: "outlined",
                          required: true,
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          className: "width-90",
                        }}
                        inputProps={{
                          value: this.state.validFrom,
                          onChange: this.handleChange,
                          required: true,
                          label: "Subscription Valid From",
                          name: "validFrom",
                          type: "date",

                          disabled: !this.checkUserHasOnBoardPermission(),
                        }}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          className: "width-90",
                        }}
                        inputProps={{
                          value: this.state.validTo,
                          onChange: this.handleChange,
                          required: true,
                          label: "Subscription Valid To",
                          name: "validTo",
                          type: "date",
                          inputProps: {
                            min: new Date().getInputDateFormat(),
                          },
                          disabled: !this.checkUserHasOnBoardPermission(),
                        }}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <CustomAutoComplete
                        autoCompleteProps={{
                          id: "multiselect-integration-admins",
                          name: "integrationAdminIds",
                          oneSelectionAllowedOnly: true,
                          options: integrationAdminsList,
                          fullWidth: true,
                          multiple: true,
                          value: this.state.integrationAdminIds,
                          onChange: this.handleIntegrationAdminChange,
                          getOptionLabel: (option) =>
                            option ? option.name : "",
                          disableCloseOnSelect: true,
                          openOnFocus: true,
                          required: true,
                          className: "width-95 overflow-hidden",
                          disabled: !this.checkUserHasOnBoardPermission(),
                        }}
                        textFieldProps={{
                          label: "Integration Admins",
                          className:
                            "input-add-screen mt-3 custom-auto-padding",
                          variant: "outlined",
                          required: true,
                        }}
                      />
                    </Grid>
                    <Grid lg={12}>
                      {this.state.integrationAdminIds.length > 1 && (
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-95",
                          }}
                          inputProps={{
                            value: this.state.primaryIntegrationAdminId,
                            onChange: this.handleChange,
                            required: true,
                            label: "Primary Integration Admin",
                            name: "primaryIntegrationAdminId",
                            select: true,
                            disabled: !this.checkUserHasOnBoardPermission(),
                          }}
                        >
                          {this.state.integrationAdminIds.map((admin) => (
                            <MenuItem value={admin.id} key={admin.id}>
                              {admin.name}
                            </MenuItem>
                          ))}
                        </CustomInput>
                      )}
                    </Grid>
                    <Grid item lg={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          className: "width-95",
                        }}
                        inputProps={{
                          value: this.state.supportAdminId,
                          onChange: this.handleChange,
                          required: false,
                          label: "Support Admin",
                          name: "supportAdminId",
                          select: true,
                          disabled: !this.checkUserHasOnBoardPermission(),
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                      >
                        {supportAdminsList.map((admin) => (
                          <MenuItem value={admin.id} key={admin.id}>
                            {admin.name}
                          </MenuItem>
                        ))}
                      </CustomInput>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6}>
                  <h6 className="block-separator-heading">
                    Vending Machine Screen Preview
                  </h6>
                  <Grid container>
                    <Grid item lg={12}>
                      <DevicePreviewPicker
                        logo={this.state.logo}
                        primaryColor={this.state.primaryColor}
                        secondaryColor={this.state.secondaryColor}
                        setDevicePreviewState={this.setDevicePreviewState}
                        logoPlaceholder="Upload Company Logo"
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <DevicePreview
                        primaryColor={this.state.primaryColor}
                        secondaryColor={this.state.secondaryColor}
                        companyLogo={this.state.logo}
                        name={this.state.name}
                      />
                    </Grid>
                  </Grid>

                  {/* deivce */}
              
                </Grid>
              </Grid>
              
              <Grid container>
                <Grid item lg={6}>
                  <Grid container>
                    <Grid item lg={12}>
                      <h6 className="block-separator-heading">
                        Company Admin Details
                      </h6>
                    </Grid>
                    <Grid item lg={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          className: "width-95",
                        }}
                        inputProps={{
                          value: this.state.companyAdminName,
                          onChange: this.handleChange,
                          required: true,
                          label: "Name",
                          name: "companyAdminName",
                          type: "text",
                          disabled: !this.checkUserHasOnBoardPermission(),
                        }}
                      />
                      <Grid container>
                        <Grid item lg={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              margin: "normal",
                              className: "width-90",
                            }}
                            inputProps={{
                              value: this.state.companyAdminEmail,
                              onChange: this.handleChange,
                              required: true,
                              label: "Email",
                              name: "companyAdminEmail",
                              type: "email",
                              disabled: !this.checkUserHasOnBoardPermission(),
                            }}
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              margin: "normal",
                              className: "width-90",
                            }}
                            inputProps={{
                              value: this.state.companyAdminContactNumber,
                              onChange: this.handleChange,
                              required: true,
                              label: "Contact Number",
                              name: "companyAdminContactNumber",
                              type: "number",
                              disabled: !this.checkUserHasOnBoardPermission(),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item lg={6}>
                  <Grid container>
                    <Grid item lg={12}>
                      <h6 className="block-separator-heading">Icon</h6>
                    </Grid>
                    
                    <Grid item lg={12}>
                      {/* <input type="file" className="mt-4" onChange={this.fileChangeHandler} /> */}
                      <UploadButton
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          className: "width-32",
                        }}
                        inputProps={{
                          name: "icon",
                          label: "Upload Icon",
                          onChange: this.fileChangeHandler,
                          className: "mt-3",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={6}>
                <h6 className="block-separator-heading">
                  Company's Operation Details
                </h6>
              </Grid>
              <Grid item lg={6}>
                <CustomInput
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    className: "width-95",
                  }}
                  inputProps={{
                    value: this.state.paymentGateways,
                    onChange: DUMMY_FUNCTION,
                    InputLabelProps: {
                      shrink: true,
                    },
                    required: nonOnboardingFieldsRequired,
                    disabled: true,
                    select: true,
                    label: "Payment Gateway",
                    name: "paymentGateways",
                  }}
                >
                  {this.props.paymentGatewaysList.map((gateway) => (
                    <MenuItem value={gateway.id} key={gateway.id}>
                      {gateway.name}
                    </MenuItem>
                  ))}
                </CustomInput>
              </Grid>
              <Grid lg={6}>
                <div className="form-input--checkbox">
                  <Checkbox
                    value={this.state.type}
                    disabled={disableCompanyTypeCheckbox}
                    onChange={
                      disableCompanyTypeCheckbox
                        ? DUMMY_FUNCTION
                        : this.handleCheckboxChange
                    }
                    name="type"
                    checked={
                      this.state.type === ENUMS.COMPANY_TYPE.WITH_FRANCHISE
                    }
                  />
                  <label
                    for="companyHasFranchises"
                    className={disableCompanyTypeCheckbox && "text-muted"}
                  >
                    Company has a Franchise?
                  </label>
                </div>
              </Grid>
              {this.props.vendingMachineBoardList.map((ele, index) => {
                return (
                  <React.Fragment>
                    <Grid container>
                      <Grid item lg={4}>
                        <div className="input-form-heading width-90">
                          <label>Vending Machine Board</label>
                          <p>{VENDIFY_BOARD_NAMES[ele.name]}</p>
                          {index === 0 && <span>(Atleast 1 compulsory)</span>}
                        </div>
                      </Grid>
                      <Grid item lg={2}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-86",
                          }}
                          inputProps={{
                            value: this.state[`numberOfBoards_${ele.name}`],
                            onChange: this.handleChange,
                            InputLabelProps: {
                              shrink: true,
                            },
                            label: "Quantity",
                            name: `numberOfBoards_${ele.name}`,
                            type: "number",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              })}{" "}
              <Grid lg={6}>
                <Tabs>
                  <TabList>
                    <Tab>Company's Registered Address</Tab>
                    <Tab>Account Details</Tab>
                  </TabList>
                  <TabPanel>
                    <Grid container>
                      <Grid lg={12}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-95",
                          }}
                          inputProps={{
                            value: this.state.address.lineOne,
                            onChange: ({ target }) =>
                              this.handleAddressChange("lineOne", target.value),
                            required: nonOnboardingFieldsRequired,
                            label: "Address Line 1",
                            name: "lineOne",
                            type: "text",
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-95",
                          }}
                          inputProps={{
                            value: this.state.address.lineTwo,
                            onChange: ({ target }) =>
                              this.handleAddressChange("lineTwo", target.value),
                            required: false,
                            label: "Address Line 2",
                            name: "lineTwo",
                            type: "text",
                          }}
                        />
                        <Grid container>
                          <Grid item lg={6}>
                            <CustomAutoComplete
                              autoCompleteProps={{
                                id: "select-country",
                                name: "countryId",
                                options: this.props.countriesList,
                                fullWidth: true,
                                value: this.state.address.countryId,
                                onChange: (ev, value) =>
                                  this.handleAddressChange("countryId", value),
                                getOptionLabel: (option) =>
                                  option && option.name ? option.name : "",
                                openOnFocus: true,
                                required: nonOnboardingFieldsRequired,
                                className: "width-90 overflow-hidden",
                              }}
                              textFieldProps={{
                                label: "Country",
                                className:
                                  "input-add-screen mt-3 custom-auto-padding",
                                variant: "outlined",
                                required: nonOnboardingFieldsRequired,
                              }}
                            />
                          </Grid>
                          <Grid item lg={6}>
                            <CustomAutoComplete
                              autoCompleteProps={{
                                id: "select-state",
                                name: "stateId",
                                options: statesList,
                                fullWidth: true,
                                value: this.state.address.stateId,
                                onChange: (ev, value) =>
                                  this.handleAddressChange("stateId", value),
                                getOptionLabel: (option) =>
                                  option && option.name ? option.name : "",

                                openOnFocus: true,
                                required: nonOnboardingFieldsRequired,
                                className: "width-90 overflow-hidden",
                              }}
                              textFieldProps={{
                                label: "State",
                                className:
                                  "input-add-screen mt-3 custom-auto-padding",
                                variant: "outlined",
                                required: nonOnboardingFieldsRequired,

                                onClick: () => {
                                  this.showWarningMessage(
                                    this.state.address.countryId,
                                    "country"
                                  );
                                },
                              }}
                            />
                          </Grid>
                          <Grid item lg={6}>
                            <CustomAutoComplete
                              autoCompleteProps={{
                                id: "select-city",
                                name: "cityId",
                                options: citiesList,
                                fullWidth: true,
                                value: this.state.address.cityId,
                                onChange: (ev, value) =>
                                  this.handleAddressChange("cityId", value),
                                getOptionLabel: (option) =>
                                  option && option.name ? option.name : "",

                                openOnFocus: true,
                                required: nonOnboardingFieldsRequired,
                                className: "width-90 overflow-hidden",
                              }}
                              textFieldProps={{
                                label: "City",
                                className:
                                  "input-add-screen mt-3 custom-auto-padding",
                                variant: "outlined",
                                required: nonOnboardingFieldsRequired,
                                onClick: () => {
                                  this.showWarningMessage(
                                    this.state.address.stateId,
                                    "state"
                                  );
                                },
                              }}
                            />
                          </Grid>
                          <Grid item lg={6}>
                            <CustomInput
                              formControlProps={{
                                fullWidth: true,
                                margin: "normal",
                                className: "width-90",
                              }}
                              inputProps={{
                                value: this.state.address.zipCode,
                                onChange: ({ target }) =>
                                  this.handleAddressChange(
                                    "zipCode",
                                    target.value
                                  ),
                                required: nonOnboardingFieldsRequired,
                                type: "number",
                                label: "Zip Code",
                                name: "zipCode",
                                className: "mt-2",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel>
                    <Grid container>
                      <Grid lg={12}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-95",
                          }}
                          inputProps={{
                            value: this.state.accountDetail.accountNumber,
                            onChange: this.handleAccountDetailsChange,
                            label: "Account Number",
                            name: "accountNumber",
                          }}
                        />
                      </Grid>
                      <Grid item lg={8}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-90",
                          }}
                          inputProps={{
                            value: this.state.accountDetail.accountName,
                            onChange: this.handleAccountDetailsChange,
                            label: "Account Name",
                            name: "accountName",
                          }}
                        />
                      </Grid>
                      <Grid item lg={4}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-90",
                          }}
                          inputProps={{
                            value: this.state.accountDetail.accountType,
                            onChange: this.handleAccountDetailsChange,
                            label: "Account Type",
                            name: "accountType",
                            select: true,
                          }}
                        >
                          <MenuItem value={"SAVING"}>Savings</MenuItem>
                          <MenuItem value={"CURRENT"}>Current</MenuItem>
                        </CustomInput>
                      </Grid>
                      <Grid item lg={8}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-90",
                          }}
                          inputProps={{
                            value: this.state.accountDetail.bankName,
                            onChange: this.handleAccountDetailsChange,
                            label: "Bank Name",
                            name: "bankName",
                          }}
                        />
                      </Grid>
                      <Grid item lg={4}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-90",
                          }}
                          inputProps={{
                            value: this.state.accountDetail.ifscCode,
                            onChange: this.handleAccountDetailsChange,
                            label: "IFSC Code",
                            name: "ifscCode",
                          }}
                        />
                      </Grid>
                      <Grid lg={12}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-95",
                          }}
                          inputProps={{
                            value: this.state.accountDetail.gstNumber,
                            onChange: this.handleAccountDetailsChange,
                            label: "GST Number",
                            name: "gstNumber",
                          }}
                        />
                      </Grid>
                      <Grid lg={12}>
                        <UploadButton
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            className: "width-85",
                          }}
                          inputProps={{
                            name: "cancelCheque",
                            label: "Upload cheque",
                            onChange: this.onFileChange,
                            className: "mt-3",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Tabs>
              </Grid>
              <Divider />
            </FormValidator>
          </Container>
          {showLicenceModal && (
            <LicenseModal
              data={this.state}
              onClose={() => this.props.history.push(`/companies`)}
            />
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    countriesList: state.countriesListReducer.list,
    paymentGatewaysList: state.paymentGatewaysListReducer.list,
    servicesList: state.servicesReducer.list,
    vendingMachineBoardList: state.vendingMachineBoardList.list,
    integrationAdminsList: state.userListReducer.integrationAdminsList,
    supportAdminsList: state.userListReducer.supportAdminsList,
    citiesList: state.citiesListReducer.list,
    statesList: state.statesListReducer.list,
    me: state.meReducer.data,
    formDirtBool: state.cancelConfirmReducer.formDirtBool,
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestCompaniesList: (urlEndPoint) =>
    dispatch(requestCompaniesList(urlEndPoint)),
  requestCountriesList: (urlEndPoint, companyId) =>
    dispatch(requestCountriesList(urlEndPoint, companyId)),
  requestStatesList: (urlEndPoint, companyId) =>
    dispatch(requestStatesList(urlEndPoint, companyId)),
  requestCitiesList: (urlEndPoint, companyId) =>
    dispatch(requestCitiesList(urlEndPoint, companyId)),
  successToast: (obj) => dispatch(successToast(obj)),
  errorToast: (obj) => dispatch(errorToast(obj)),
  requestUserList: (urlEndPoint) => dispatch(requestUserList(urlEndPoint)),
  requestServicesList: (urlEndPoint) =>
    dispatch(requestServicesList(urlEndPoint)),
  requestIntegrationAdminsList: (urlEndPoint) =>
    dispatch(requestIntegrationAdminsList(urlEndPoint)),
  requestSupportAdminsList: (urlEndPoint) =>
    dispatch(requestSupportAdminsList(urlEndPoint)),
  requestPaymentGatewaysList: (urlEndPoint, companyId) =>
    dispatch(requestPaymentGatewaysList(urlEndPoint, companyId)),
  requestMachineBoardsList: (urlEndPoint) =>
    dispatch(requestVendingMachineBoardsList(urlEndPoint)),
  resetCitiesList: () => dispatch(resetCitiesList()),
  resetStatesList: () => dispatch(resetStatesList()),
  checkShowModal: (method) => dispatch(checkShowModal(method)),
  setFormDirtiness: () => dispatch(setFormDirtiness()),
  resetFormDirtiness: () => dispatch(resetFormDirtiness()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);
